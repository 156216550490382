<template>
  <b-modal
    id="modal-add-quize"
    v-model="show"
    title="Tạo nhiều câu hỏi tại quiz"
    centered
    hide-footer
    @hidden="resetModal"
  >
    <b-overlay :show="loading">
      <form @submit.prevent="handleValidate">
        <b-row>
          <b-col cols="7" class="border-right">
            <b-row>
              <b-col>
                <div class="d-flex align-items-center">
                  <span class="font-weight-bolder"
                    >Tổng số câu hỏi: {{ getQuestionTotal }}</span
                  >
                  <b-button
                    class="btn btn-success ml-10"
                    type="button"
                    @click="preview = true"
                  >
                    <span class="svg-icon mr-0">
                      <inline-svg
                        src="/media/svg/icons/Neolex/Basic/save.svg"
                      />
                    </span>
                    Xem câu hỏi
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <div>
              <template v-for="(group, index) in questionGroups">
                <b-row :key="index">
                  <b-col>
                    <basic-select
                      label="Chọn cấp độ"
                      placeholder="--- Chọn cấp độ ---"
                      name="level"
                      :solid="false"
                      :allowEmpty="true"
                      value-label="text"
                      track-by="value"
                      :value.sync="group.level"
                      :options="levels"
                      changeValueByObject
                      disabled
                    />
                  </b-col>
                  <b-col>
                    <basic-select
                      label="Chọn chủ đề"
                      placeholder="--- Chọn chủ đề ---"
                      name="module"
                      :solid="false"
                      :allowEmpty="true"
                      value-label="text"
                      track-by="value"
                      :value.sync="group.module"
                      :options="modules"
                      changeValueByObject
                      disabled
                    />
                  </b-col>
                  <b-col>
                    <basic-select
                      label="Chọn số câu hỏi"
                      placeholder="--- Chọn số câu hỏi ---"
                      name="count-question"
                      :solid="false"
                      :allowEmpty="true"
                      value-label="text"
                      track-by="value"
                      :value.sync="group.questionCount"
                      :options="questionCounts"
                      changeValueByObject
                      disabled
                    />
                  </b-col>
                  <b-col cols="1">
                    <b-button
                      class="btn btn-delete ml-0 mt-9"
                      size="sm"
                      type="button"
                      @click="removeQuestionGroup(group.id, group.groupNumber)"
                    >
                      <span class="svg-icon mr-0">
                        <inline-svg
                          src="/media/svg/icons/Neolex/Basic/trash-2.svg"
                        />
                      </span>
                    </b-button>
                  </b-col>
                </b-row>
              </template>
              <b-row>
                <b-col>
                  <basic-select
                    label="Chọn cấp độ"
                    placeholder="--- Chọn cấp độ ---"
                    name="level"
                    :solid="false"
                    :allowEmpty="true"
                    value-label="text"
                    track-by="value"
                    :value.sync="questionGroup.level"
                    :options="levels"
                    changeValueByObject
                    @update:value="getQuestions"
                  />
                </b-col>
                <b-col>
                  <basic-select
                    label="Chọn chủ đề"
                    placeholder="--- Chọn chủ đề ---"
                    name="module"
                    :solid="false"
                    :allowEmpty="true"
                    value-label="text"
                    track-by="value"
                    :value.sync="questionGroup.module"
                    :options="modules"
                    changeValueByObject
                    @update:value="getQuestions"
                  />
                </b-col>
                <b-col>
                  <basic-select
                    label="Chọn số câu hỏi"
                    placeholder="--- Chọn số câu hỏi ---"
                    name="count-question"
                    required
                    :solid="false"
                    :allowEmpty="true"
                    value-label="text"
                    track-by="value"
                    :value.sync="questionGroup.questionCount"
                    :options="questionCounts"
                    changeValueByObject
                    :state="validateState(`count-question`)"
                    :invalid-feedback="errors.first(`count-question`)"
                    v-validate="{
                      required: isRequireQuestionNumber,
                    }"
                    data-vv-as="Số câu hỏi"
                    @update:value="$validator.reset({ name: `count-question` })"
                  />
                </b-col>
                <b-col cols="1">
                  <b-button
                    class="btn btn-add mt-9 ml-0"
                    type="button"
                    size="sm"
                    :disabled="!questionGroup.questionCount"
                    @click="addQuestionGroup"
                  >
                    <span class="svg-icon mr-0">
                      <inline-svg
                        src="/media/svg/icons/Neolex/Basic/save.svg"
                      />
                    </span>
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col cols="5">
            <template v-if="preview">
              <p class="font-weight-bolder">
                Các câu hỏi trùng đã được loại trừ:
                {{ selectedQuestions.length }}
              </p>
              <div class="wrap-question">
                <template v-for="(question, index) in selectedQuestions">
                  <AnswerTable
                    :key="index"
                    :answers="question.quizAnswers"
                    :name="question.name"
                    :index="index"
                    :id="question.id"
                    :ref="`table-answer-${question.id}`"
                  />
                </template>
              </div>
            </template>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="text-right">
              <b-button class="btn ml-3" type="button" @click="resetModal">
                Huỷ
              </b-button>
              <b-button class="btn btn-success ml-3" type="submit">
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
                </span>
                Lưu
              </b-button>
            </div>
          </b-col>
        </b-row>
      </form>
    </b-overlay>
  </b-modal>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import { EDIT_QUESTION_GROUP } from '@/core/services/store/survey.module';
import { MODAL_STATUS } from '../../../../core/plugins/constants';
import uniqBy from 'lodash/uniqBy';
import cloneDeep from 'lodash/cloneDeep';
const { mapState, mapActions } = createNamespacedHelpers('survey');
export default {
  name: 'ModalAddQuize',
  components: {
    AnswerTable: () => import('./AnswerTable.vue'),
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      answer: {
        isAddNew: true,
        name: null,
        score: null,
      },
      levels: [],
      modules: [],
      questionGroups: [],
      questionCounts: [],
      questionGroup: {
        isAddNew: true,
        level: null,
        module: null,
        questionCount: null,
      },
      questions: [],
      selectedQuestions: [],
      preview: false,
      isRequireQuestionNumber: false,
    };
  },
  computed: {
    ...mapState({ lastOrder: 'lastOrder', storedQuestions: 'questions' }),
    show: {
      get() {
        return this.$store.state.context.modalShow;
      },
      set(val) {
        this.$store.commit(`context/setModal`, val);
      },
    },
    searchQuestion() {
      return {
        lessonLevelId: this.questionGroup.level?.value || null,
        lessonModuleId: this.questionGroup.module?.value || null,
      };
    },
    getQuestionTotal() {
      let count = 0;
      this.questionGroups.length &&
        this.questionGroups.forEach((el) => {
          count += Number(el.questionCount.value);
        });
      return count;
    },
  },
  async created() {
    this.loading = true;
    await Promise.all([this.getQuestions(), this.getPrepare()]);
    this.loading = false;
  },
  methods: {
    ...mapActions({ EDIT_QUESTION_GROUP }),
    resetModal() {
      this.$emit('reset');
    },
    async addQuestionGroup() {
      const newItem = {
        level: this.questionGroup.level,
        module: this.questionGroup.module,
        questionCount: this.questionGroup.questionCount,
        id: this.$uuid.v1(),
        groupNumber: this.questionGroups.length + 1,
      };

      await (this.isRequireQuestionNumber = !newItem.questionCount);
      await this.delay(50);
      const valid = await this.$validator.validate(`count-question`);

      if (!valid) return;

      this.questionGroups.push(newItem);
      await this.addQuestionsWithCount(
        Number(newItem.questionCount.value),
        this.questionGroups.length,
      );
      this.questionGroup = {
        isAddNew: true,
        level: null,
        module: null,
        questionCount: null,
      };
      this.getQuestions();
    },
    removeQuestionGroup(id, groupNumber) {
      const deleteIndex = this.questionGroups.findIndex((el) => el.id === id);
      this.questionGroups.splice(deleteIndex, 1);
      this.selectedQuestions = [...this.selectedQuestions].filter(
        (el) => el.groupNumber !== groupNumber,
      );
    },
    insert(arr, index, newItem) {
      return [...arr.slice(0, index), newItem, ...arr.slice(index)];
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    async getPrepare() {
      try {
        const { meta, data, error } = await this.$api.get(
          '/Quiz/PrepareSearchAvailableQuiz',
        );
        if (!meta.success) {
          this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.levels = data.lessonLevels;
        this.modules = data.lessonModules;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      }
    },
    async getQuestions() {
      try {
        const { meta, data, error } = await this.$api.get(
          '/Quiz/SearchAvailableQuiz',
          {
            params: this.searchQuestion,
          },
        );
        if (!meta.success) {
          this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.questions =
          data.length &&
          data.map((el) => {
            return {
              ...el,
              quizAnswers: this.converAnswerScore(el.quizAnswers),
            };
          });
        this.questionCounts = Array.from(
          { length: this.questions.length + 1 },
          (_, i) => {
            return {
              text: `${i}`,
              value: `${i}`,
            };
          },
        );
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      }
    },
    addQuestionsWithCount(count, groupNumber) {
      return new Promise((resolve) => {
        const array = this.getRandom(this.questions, count);
        array.forEach((el) => {
          el.groupNumber = groupNumber;
        });
        this.selectedQuestions = uniqBy(
          [...this.selectedQuestions, ...array],
          'id',
        );
        resolve();
      });
    },
    converAnswerScore(answers) {
      let array = [...answers];
      array = array.map((el) => {
        return {
          ...el,
          score: el.isCorrect ? 1 : 0,
        };
      });
      return array;
    },
    validateQuestionAnswers() {
      if (!this.preview || !this.questionGroups.length) return;
      return new Promise(async (resolve) => {
        let isInvalid = false;
        new Promise((rs) => {
          this.selectedQuestions.forEach(async (el) => {
            const tableAnswer = this.$refs[`table-answer-${el.id}`][0];
            tableAnswer.handleValidate();
            await this.delay(50);
            if (tableAnswer.isInvalid) {
              rs(true);
            }
          });
        }).then((res) => (isInvalid = res));
        await this.delay(50);
        resolve(isInvalid);
      });
    },
    async handleValidate() {
      const isInvalid = await this.validateQuestionAnswers();
      if (isInvalid) return;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submit();
        }
        return;
      });
    },
    async submit() {
      this.loading = true;
      let questions = [...this.selectedQuestions].map((el, index) => {
        return {
          answers: this.convertAnswer(el.quizAnswers),
          order: this.lastOrder + index,
          isRelatedPatients: false,
          isRelatedQuestions: false,
          isScore: false,
          code: el.code,
          name: el.name,
          type: el.type,
          modelStatus: MODAL_STATUS.NEW,
          id: el.id,
        };
      });
      let newStoredQuestions = cloneDeep(this.storedQuestions).map((el) => {
        return {
          ...el,
          code: el.code.includes('_') ? el.code.split('_')[1] : el.code,
        };
      });

      questions = uniqBy([...newStoredQuestions, ...questions], 'code').filter(
        (el) => el.modelStatus === MODAL_STATUS.NEW,
      );

      const payload = {
        id: this.id,
        name: null,
        code: null,
        questions: [
          ...this.storedQuestions,
          ...questions.map((el) => ({
            ...el,
            code: `${this.generateReferralCode(6)[0]}_${el.code}`,
          })),
        ],
      };

      const { error } = await this.EDIT_QUESTION_GROUP(payload);
      if (error) {
        this.loading = false;
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      }
      this.loading = false;
      this.resetModal();
    },
    convertAnswer(array) {
      let newAnswers = [...array].filter((el) => !el.isAddNew);
      newAnswers = newAnswers.map((item, index) => {
        return {
          ...item,
          order: index + 1,
          id: null,
          point: Number(item.score),
          content: item.name,
          modelStatus: MODAL_STATUS.NEW,
        };
      });
      return newAnswers;
    },
  },
};
</script>
<style lang="scss">
.btn-add {
  background-color: #def2f1 !important;
  border-color: #def2f1 !important;
  color: #008479 !important;
}

.btn-delete {
  color: #ff5756 !important;
  background-color: #ffebef !important;
  border-color: #ffebef !important;
}

.score-input {
  .form-group {
    margin-bottom: 0 !important;
  }
}

#modal-add-quize {
  .modal-dialog {
    max-width: 80%;
  }
}
</style>

<style lang="scss" scoped>
.wrap-question {
  max-height: 500px;
  overflow: auto;
}
</style>
